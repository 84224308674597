.HomePageHeroGradient {
   
    background: linear-gradient(-45deg, #ee7752, #f78fb7, #877de3, #94e6d3);
    background-size: 200% 200%;
    animation: bg-animation 15s ease infinite;
    height: 100vh;          

}

@keyframes bg-animation {
    0%{background-position:0% 73%}
    50%{background-position:100% 28%}
    100%{background-position:0% 73%}
}


.blob
{    position: absolute;
    top: 0px;
    left: 0px;
    width: 500px;
    height: 500px;
    background: none;
}

.blob:nth-child(2) 
{
    filter: blur(70px);
}



